<template>
	<div>
		<Navbar />
		<Header title="Create Campaign" excerpt="Create new campaigns and schedule them to run in future." />

		<!-- CONTENT -->
		<div class="container">
			<div class="row">
				<div class="col-md-8">
					<div class="alert alert-success" v-if="form.events.success">{{ form.events.success }}</div>
					<div class="alert alert-danger" v-if="form.events.error">{{ form.events.error }}</div>

					<div class="form-row">
						<div class="col">
							<div class="form-group">
								<label>From</label>
								<b-form-select
									id="inline-form-custom-select-pref"
									class="w-100"
									:options="masks"
									v-model="form.fields.sender">
								</b-form-select>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<label>Group</label>
								<b-form-select
									id="inline-form-custom-select-pref"
									class="w-100"
									:options="groups"
									v-model="form.fields.group">
								</b-form-select>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="col">
							<div class="form-group">
								<label>Schedule Date</label>
								<input class="form-control" type="date" v-model="form.fields.date" />
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<label>Schedule Time</label>
								<input class="form-control" type="time" v-model="form.fields.time" />
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="col">
							<div class="form-group">
								<label>Message</label>
								<textarea class="form-control" v-model="form.fields.message"></textarea>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="col">
							<div class="form-group">
								<b-button v-on:click="On_Submit" variant="primary" block>Schedule</b-button>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import config from "../../../config.json"
import Header from "@/components/Header";
import Navbar from "@/components/Navbar";

export default {
	name: "CreateCampaign",
	components: { Header, Navbar },

	data: () => ({
		form: {
			events: {
				error: null,
				success: null
			},
			fields: {
				sender: null,
				group: null,
				date: null,
				time: null,
				message: null
			}
		}
	}),

	mounted() {
		this.$store.dispatch("masks/initialize")
		this.$store.dispatch("groups/initialize")
	},

	computed: {

		masks() {
			let masks = []
			this.$store.state.masks.masks.forEach(mask => {
				masks.push({
					id: mask.id,
					text: mask.name,
					value: mask.id
				})
			});
			return masks
		},

		groups() {
			let groups = []
			this.$store.state.groups.groups.forEach(group => {
				groups.push({
					id: group.id,
					text: group.title,
					value: group.id
				})
			})
			return groups
		}

	},

	methods: {
		On_Submit() {

			/**
			 * Reset form errors and success notice because this
			 * is going to be new submission to contact creation
			 * and we don't need any of the previous errors or
			 * anything
			 */
			this.form.events.success = null
			this.form.events.error = null

			/**
			 * We have to create unix time stamp based on the date and
			 * the time that campaign is scheduled before submitting
			 * data into the API
			 */
			let date = Date.parse(this.form.fields.date + "T" + this.form.fields.time + ":00+05:30") / 1000

			/**
			 * Submit information to the API and create campaign for
			 * the specified criteria
			 */
			fetch(config.api.protocol + "://" + config.api.fqdn + "/v1.0/campaign", {
				method: "POST",
				headers: { "Authorization": "Bearer " + localStorage.getItem("token") },
				body: JSON.stringify({
					from: this.form.fields.sender,
					group: this.form.fields.group,
					schedule: date,
					message: this.form.fields.message
				})
			}).then(Response => Response.json()).then(Response => {
				if (Response.status == "success") {
					this.form.events.success = "Campaign created successfully."
				} else {
					if (Response.status == "failed") {
						this.form.events.error = Response.error.message
					} else {
						this.form.events.error = "Something went wrong"
					}
				}
			})

		}
	}
}
</script>

<style lang="scss" scope>
textarea {
	min-height: 200px;
}
</style>